import React, { type FC } from "react";
import { LiaCheckCircle } from "react-icons/lia";
import { useI18next } from "gatsby-plugin-react-i18next";

import Modal from "@components/modal";
import CookieType from "@constants/CookieType";
import Languages from "@data/languages.json";
import type Language from "@interfaces/Language";
import { useAuthenticationState } from "@stores/authentication";
import axios from "@utilities/axios";
import { cn, primaryRipple } from "@utilities/helper";
import Cookies from "js-cookie";

const LangModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}> = ({ isModalOpen, setIsModalOpen }) => {
  const { t, changeLanguage, language } = useI18next();
  const authenticationState = useAuthenticationState();

  const updateLang = async (lang: string): Promise<void> => {
    Cookies.set(CookieType.LANG, lang, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    setIsModalOpen(false);
    if (authenticationState.isAuthenticated()) {
      const changeLanguageParams = new URLSearchParams({
        action: "update-language",
        language: lang,
        license_token: authenticationState.getToken(),
      });
      const changeLanguageUrl = `/my_account_details/?${changeLanguageParams.toString()}`;
      await axios.get(changeLanguageUrl);
    }
    await changeLanguage(lang);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isCloseButtonExist={true}
      bgColor={"#f3f3f3"}
      title={t("language") ?? ""}
      isContentScrollable={true}
    >
      <div className="flex flex-col gap-3">
        {Languages.map((lang: Language, i: number) => {
          return (
            <button
              ref={primaryRipple}
              key={lang.name}
              onClick={() => {
                updateLang(lang.id);
              }}
              className={cn(
                "space-between focus:custom-primary-focus-ring group flex w-full items-center rounded-3xl !border-2 border-solid border-primary-200 p-3 shadow-lg shadow-gray-300 transition duration-300",
                {
                  "bg-primary": lang.id === language,
                  "bg-white hover:opacity-80": lang.id !== language,
                },
              )}
            >
              <span
                className={cn(
                  "block w-full no-underline transition duration-300",
                  {
                    "text-white": lang.id === language,
                    "text-black group-hover:text-primary": lang.id !== language,
                  },
                )}
              >
                {lang.name}
              </span>
              <LiaCheckCircle className="size-5 text-white" />
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

export default LangModal;
