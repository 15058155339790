import { useRipple } from "@chaocore/ripple";
import CookieType from "@constants/CookieType";
import Countries from "@data/countries.json";
import LandingPricing from "@data/landing-pricing.json";
import Regions from "@data/regions.json";
import type CoverageItem from "@interfaces/CoverageItem";
import { type default as LandingPricingType } from "@interfaces/LandingPricing";
import type Product from "@interfaces/Product";
import type Region from "@interfaces/Region";
import tailwindConfig from "@root/tailwind.config";
import { useAccountDetailStore } from "@stores/account_detail";
import { useAuthenticationStore } from "@stores/authentication";
import { useConfigurationStore } from "@stores/configuration";
import { usePaymentStore } from "@stores/payment";
import axios from "@utilities/axios";
import { type ClassValue, clsx } from "clsx";
import { type TFunction } from "i18next";
import Cookies from "js-cookie";
import { twMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";

export const cn = (...inputs: ClassValue[]): string => {
  return twMerge(clsx(inputs));
};

export const isDebugMode = (): boolean => process.env.GATSBY_DEBUG === "true";

export const isProductionMode = (): boolean =>
  typeof window !== "undefined" &&
  !!window.location?.hostname &&
  !!process.env.GATSBY_SITE_URL?.includes(window.location.hostname);

export const defaultRipple = useRipple({});

export const primaryRipple = useRipple({
  color: resolveConfig(tailwindConfig).theme.colors.primary.DEFAULT,
});

export const getDurationText = (
  t: TFunction<"translation", undefined, "translation">,
  product: Product,
): string => {
  let durationText = "";
  switch (product.duration) {
    case -1:
      durationText = t("no_expiration");
      break;
    case 7:
    case 30:
      if (product.has_subscription) {
        durationText = t("monthly");
      } else {
        durationText = product.duration + " " + t("days");
      }
      break;
  }
  return durationText;
};

export const handleRedirectToApp = (): void => {
  if (typeof window !== "undefined" && window.location.href) {
    const appUrl = process.env.GATSBY_APP_URL ?? "";

    let fallbackUrl;
    if (/android/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_PLAY_STORE_LINK ?? "/";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_APP_STORE_LINK ?? "/";
    } else {
      fallbackUrl = process.env.GATSBY_SITE_URL ?? "/";
    }

    window.location.href = appUrl;

    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 3000);
  }
};

export const clearUserData = (): void => {
  useAccountDetailStore.getState().clear();
  useAuthenticationStore.getState().clear();
  usePaymentStore.getState().clear();
  useConfigurationStore.getState().reset();
  Cookies.remove(CookieType.CURRENCY, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.TOKEN, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.ACCOUNT_NUMBER, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.HASHED_PASSWORD, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.AFFILIATE, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
};

export const range = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const getLocationData = (
  language: string,
  location: string,
  productId?: string,
):
  | {
      id: string;
      title: string;
      description: string;
      locationText: string;
      imgSrc: string;
      isRegional: boolean;
      coverageList: CoverageItem[];
    }
  | false => {
  let isRegional: boolean = false;
  let id: string = "";
  let title: string = "";
  let description: string = "";
  let locationText: string = "";
  let imgSrc: string = "";
  const coverageList: CoverageItem[] = []; // for region

  const imgBasePath = "/images/";

  interface LocationDataType {
    id: string;
    title: string;
    description: string;
    imgSrc: string;
  }
  const getCountryData = (id: string = ""): LocationDataType | null => {
    const countries = Countries as LandingPricingType["countries"];
    const countryKeys = Object.keys(countries);
    const matchedCountry = countryKeys.find(
      country => country.toLowerCase() === id.toLowerCase(),
    );

    if (!matchedCountry) return null;

    const country =
      countries[matchedCountry.toUpperCase()][language] ??
      countries[matchedCountry.toUpperCase()].en;

    return {
      id: matchedCountry,
      title: country?.country_name ?? "",
      description: country?.country_description ?? "",
      imgSrc: `${imgBasePath}countries/${matchedCountry.toLowerCase()}.png`,
    };
  };
  const getRegionData = (regionSlug: string = ""): LocationDataType | null => {
    const matchedRegion = (Regions as Region[]).find(
      (region: Region) => region.slug === regionSlug,
    );

    if (!matchedRegion) return null;

    return {
      id: matchedRegion.name.en,
      title: matchedRegion.name[language] ?? matchedRegion.name.en ?? "",
      description: matchedRegion.description[language] ?? "",
      imgSrc: `${imgBasePath}regions/${matchedRegion.name.en
        .replace(" ", "_")
        .toLowerCase()}.png`,
    };
  };
  const buildCoverageList = (coverages: string[] = []): void => {
    const countries = Countries as LandingPricingType["countries"];
    coverages.forEach(coverage => {
      const localizedCoverage =
        countries[coverage][language] ?? countries[coverage].en;

      if (localizedCoverage) {
        coverageList.push({
          coverage,
          country_name: localizedCoverage.country_name,
        });
      }
    });
  };

  if (productId) {
    const product = (LandingPricing as LandingPricingType).products.find(
      (product: Product) => product.id === productId,
    );

    if (!product) return false;

    if (product.regional) {
      // Region
      isRegional = true;
      id = product.region
        .replace(/[0-9-]/g, "")
        .trim()
        .toLowerCase()
        .split(/\s+/)
        .map(word => word[0]?.toUpperCase() + word.slice(1))
        .join(" ");

      const regionData = getRegionData(id.replace(/\s+/g, "-").toLowerCase());

      if (regionData) {
        title = regionData.title;
        description = regionData.description;
        locationText = title.replace(/[0-9-]/g, "").trim();
        imgSrc = regionData.imgSrc;
      }

      buildCoverageList(product.coverages || []);
    } else {
      // Country
      isRegional = false;
      id = product.coverages[0]?.toLowerCase();
      const countryData = getCountryData(id);

      if (!countryData) return false;

      title = countryData.title;
      description = countryData.description;
      locationText = title;
      imgSrc = countryData.imgSrc;
    }
  } else {
    const regionData = getRegionData(location);

    if (regionData) {
      // Region
      isRegional = true;
      id = regionData.id;
      title = regionData.title;
      description = regionData.description;
      locationText = title.replace(/[0-9-]/g, "").trim();
      imgSrc = regionData.imgSrc;

      const products = (LandingPricing as LandingPricingType).products.filter(
        (product: Product) =>
          product.regional &&
          product.region
            .replace(/[0-9-]/g, "")
            .trim()
            .toLowerCase() === id.toLowerCase(),
      );

      const productWithMostCoverages = products.reduce(
        (nextProduct: Product, currentProduct: Product) =>
          currentProduct.coverages.length > nextProduct.coverages.length
            ? currentProduct
            : nextProduct,
        products[0],
      );

      buildCoverageList(productWithMostCoverages?.coverages || []);
    } else {
      // Country
      isRegional = false;
      id = location;
      const countryData = getCountryData(id);

      if (!countryData) return false;

      title = countryData.title;
      description = countryData.description;
      locationText = title;
      imgSrc = countryData.imgSrc;
    }
  }

  if (title) {
    title += " eSIM";
  }

  return {
    id,
    title,
    description,
    locationText,
    imgSrc,
    isRegional,
    coverageList,
  };
};

export const createPaymentIntent = async (
  product: Product | null,
  currency: string,
  authToken: string,
): Promise<{ paymentIntentId: string; clientSecret: string } | undefined> => {
  if (!product) {
    return;
  }
  const paymentState = usePaymentStore.getState();
  const params = new URLSearchParams({
    product: product.product,
    currency,
    bundle_name: "landing-page-new",
    license_token: authToken,
  });

  if (product.esim) {
    params.set("action", "esim");
  } else {
    params.set("action", "sim");
    params.set("address", "no_address");
    params.set("shipment", "regular");
    params.set("method", product.currency);
  }

  if (product.offer_type === "eligible-for-free") {
    params.set("free_product", "1");
  }

  const url = `/payment_methods/?${params.toString()}`;
  try {
    const response = await axios.get(url);

    if (isDebugMode()) {
      console.log("JSON response:", response.data);
    }
    const paymentIntentId = response.data.transaction.id ?? "";
    const clientSecret = response.data.transaction.client_secret ?? "";
    if (!paymentIntentId || !clientSecret) {
      return;
    }
    paymentState.setPaymentIntentId(paymentIntentId);
    paymentState.setClientSecret(clientSecret);
    return {
      paymentIntentId,
      clientSecret,
    };
  } catch (error: any) {
    if (error.response) {
      console.error(`Response status: ${error.response.status}`);
    } else {
      console.error(error.message);
    }
  }
};
