/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { type FC, type ReactElement } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const Seo: FC<{
  title?: string;
  description?: string;
  lang?: string;
  image?: string;
  children?: any;
}> = ({ title, description, lang, image, children }): ReactElement => {
  const { t, path } = useI18next();
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          og_image
          siteUrl
        }
      }
    }
  `);
  const siteUrl = process.env.GATSBY_SITE_URL || site.siteMetadata.siteUrl;
  const metaUrl = `${siteUrl.endsWith("/") ? siteUrl : `${siteUrl}/`}${path}`;
  const metaImage = image
    ? `${siteUrl}${image}`
    : `${siteUrl}${site.siteMetadata.og_image}`;

  const ogTitleKey = "og_title";
  const ogDescriptionKey = "og_description";
  const targetTitle =
    title ||
    (t(ogTitleKey) && t(ogTitleKey) !== ogTitleKey ? t(ogTitleKey) : null) ||
    site.siteMetadata.title;
  const targetDescription =
    description ||
    (t(ogDescriptionKey) && t(ogDescriptionKey) !== ogDescriptionKey
      ? t(ogDescriptionKey)
      : null) ||
    site.siteMetadata.description;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{targetTitle}</title>
      <meta name="description" content={targetDescription} />
      <meta property="og:title" content={targetTitle} />
      <meta property="og:description" content={targetDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={targetTitle} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:description" content={targetDescription} />
      <meta
        name="keywords"
        content="eSIM, buy eSIM, data eSIM, global eSIM, regional eSIM, shop eSIM, e-SIM, eSIM marketplace, Traveler, data plan, connectivity, purchase local eSIM, buy e-SIM for international travel, world best travel esim, world best travel sim provider, cheap data, affordable global eSIM plans, best data plans for travelers 2025, 5G-enabled eSIM plans, download eSIM profile, eSIM for IOT, best esim provider, multi-country eSIM, travel esim, affordable eSIM, reliable eSIM, global coverage, best travel sim award winner, Ciao, Local Plan eSIM, Regional Plan eSIM"
      />
      <link rel="canonical" href={metaUrl} />
      {children}
    </Helmet>
  );
};

export default Seo;
