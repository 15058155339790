import React, { type FC, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useI18next } from "gatsby-plugin-react-i18next";

import Header from "@components/header";
import LiveChatPopup from "@components/live_chat_popup";
import CookieType from "@constants/CookieType";
import {
  useAccountDetailState,
  useAccountDetailStore,
} from "@stores/account_detail";
import {
  useAuthenticationState,
  useAuthenticationStore,
} from "@stores/authentication";
import {
  useConfigurationState,
  useConfigurationStore,
} from "@stores/configuration";
import { loadCurrencies } from "@utilities/currencies";
import { cn, isDebugMode, isProductionMode } from "@utilities/helper";
import Cookies from "js-cookie";

const Layout: FC<{ showHeader?: boolean; children?: any }> = ({
  showHeader,
  children,
}) => {
  const { path, language, changeLanguage } = useI18next();

  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  const authenticationState = useAuthenticationState();
  const accountDetailState = useAccountDetailState();
  const configurationState = useConfigurationState();

  const authPaths = ["login", "register", "forgot-password"];
  const isAuthPage = authPaths.some(authPath => path.includes(authPath));
  const shouldShowHeader = showHeader ?? !isAuthPage;

  const initializeData = async () => {
    await loadCurrencies();
    const tokenFromCookie = Cookies.get(CookieType.TOKEN) ?? "";
    if (isProductionMode()) {
      if (authenticationState.getToken() !== tokenFromCookie) {
        if (tokenFromCookie) {
          authenticationState.setToken(tokenFromCookie);
          accountDetailState.load(tokenFromCookie);
        } else {
          authenticationState.clear();
          accountDetailState.clear();
        }
        if (isDebugMode()) {
          console.log("loaded token from cookie 🍪.");
        }
      }
    }

    const currencyFromCookie = Cookies.get(CookieType.CURRENCY) ?? "";
    if (isProductionMode()) {
      if (configurationState.currency !== currencyFromCookie) {
        configurationState.updateCurrency(
          currencyFromCookie ?? process.env.GATSBY_DEFAULT_CURRENCY,
        );
        if (isDebugMode()) {
          console.log("loaded currency token from cookie 🍪.");
        }
      }
    }

    if (typeof window !== "undefined" && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      configurationState.updateAffiliate(params.get("a") ?? "site");
      if (isDebugMode()) {
        console.log("registered affiliate to cookie 🍪.");
      }

      configurationState.updateCouponCode(params.get("coupon-code") ?? "");
      if (isDebugMode()) {
        console.log("registered coupon code.");
      }
    }

    const langFromCookie = Cookies.get(CookieType.LANG) ?? "";
    if (isProductionMode()) {
      if (language !== langFromCookie) {
        if (langFromCookie) {
          changeLanguage(langFromCookie);
          if (isDebugMode()) {
            console.log("loaded language token from cookie 🍪.");
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isHydrated) {
      initializeData();
    } else {
      useAccountDetailStore.persist.rehydrate();
      useAuthenticationStore.persist.rehydrate();
      useConfigurationStore.persist.rehydrate();
      setIsHydrated(true);
    }
  }, [isHydrated]);

  return (
    <div className="contents selection:bg-primary/80 selection:text-white">
      {shouldShowHeader && <Header />}
      <div
        className={cn({
          "pt-24": shouldShowHeader,
          "!py-0": !shouldShowHeader,
        })}
      >
        <main>{children}</main>
      </div>
      <ToastContainer className="text-sm" draggable />
      <LiveChatPopup />
    </div>
  );
};

export default Layout;
