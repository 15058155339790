import CountryCurrency from "@data/country-currency.json";
import type Coupon from "@interfaces/Coupon";
import { type CreditCard, type Wallet } from "@interfaces/Payment";
import { useConfigurationStore } from "@stores/configuration";
import axios from "@utilities/axios";
import { encryptedStorage, hashKey } from "@utilities/encrypted-storage";
import { isDebugMode } from "@utilities/helper";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type CountryCurrencyType = Record<string, string>;

export interface AccountDetailState {
  license: {
    cli: string;
    name: string;
    phone: string;
    email: string;
  };
  profile: {
    image: string;
  };
  referralCode: string;
  lastCountry: {
    iso2: string;
    name: string;
  };
  paymentMethods: {
    cards: CreditCard[];
    wallets: Wallet[];
    coupons: Coupon[];
  };
  load: (licenseToken: string, isUpdateCurrency?: boolean) => Promise<void>;
  updatePaymentMethods: (
    cards: CreditCard[],
    wallets: Wallet[],
    coupons: Coupon[],
  ) => void;
  clear: () => void;
}

export const useAccountDetailStore = create<AccountDetailState>()(
  persist(
    set => ({
      license: {
        cli: "",
        name: "",
        phone: "",
        email: "",
      },
      profile: {
        image: "",
      },
      referralCode: "",
      lastCountry: {
        iso2: "",
        name: "",
      },
      paymentMethods: {
        cards: [],
        wallets: [],
        coupons: [],
      },
      load: async (licenseToken, isUpdateCurrency: boolean = false) => {
        const mapParams = new URLSearchParams({
          action: "get_current_location",
          license_token: licenseToken,
        });
        const mapUrl = `/maps_autocomplete/?${mapParams.toString()}`;
        axios
          .get(mapUrl)
          .then(response => {
            if (isDebugMode()) {
              console.log("Map Autocomplete JSON response:", response.data);
            }
            const lastCountryIso2 = response.data.last_country_iso2;
            set({
              lastCountry: {
                iso2: lastCountryIso2,
                name: response.data.last_country_name,
              },
            });
            let currency = CountryCurrency.find(
              (countryCurrency: CountryCurrencyType) =>
                lastCountryIso2 in countryCurrency,
            );
            currency = currency
              ? currency[lastCountryIso2]
              : process.env.GATSBY_DEFAULT_CURRENCY;
            if (isUpdateCurrency && currency) {
              useConfigurationStore.getState().updateCurrency(currency);
            }

            const paymentParams = new URLSearchParams({
              license_token: licenseToken,
              currency,
            });
            const paymentMethodUrl = `/payment_methods/?${paymentParams.toString()}`;
            axios
              .get(paymentMethodUrl)
              .then(response => {
                if (isDebugMode()) {
                  console.log("Payment Method JSON response:", response.data);
                }

                set({
                  paymentMethods: {
                    cards: response.data.cards,
                    wallets: response.data.wallets,
                    coupons: response.data.coupons,
                  },
                });
              })
              .catch(error => {
                if (error.response) {
                  console.error(`Response status: ${error.response.status}`);
                } else {
                  console.error(error.message);
                }
              });
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
          });

        const accountDetailParams = new URLSearchParams({
          license_token: licenseToken,
        });
        const accountDetailUrl = `/my_account_details/?${accountDetailParams.toString()}`;
        axios.get(accountDetailUrl).then(response => {
          if (isDebugMode()) {
            console.log("Account Detail JSON response:", response.data);
          }
          set({
            license: {
              cli: response.data.license_cli,
              name: response.data.license_name,
              phone: response.data.license_phone,
              email: response.data.license_email,
            },
          });
        });

        const profileParams = new URLSearchParams({
          license_token: licenseToken,
        });
        const profileImageUrl = `/media/?${profileParams.toString()}`;
        axios.get(profileImageUrl).then(response => {
          set({
            profile: {
              image: response.data.profile_image,
            },
          });
        });

        const referralParams = new URLSearchParams({
          license_token: licenseToken,
        });
        const referralUrl = `/referral/?${referralParams.toString()}`;
        axios.get(referralUrl).then(response => {
          set({
            referralCode: response.data.ref_code,
          });
        });
      },
      updatePaymentMethods: (
        cards: CreditCard[],
        wallets: Wallet[],
        coupons: Coupon[],
      ) => {
        set({
          paymentMethods: {
            cards,
            wallets,
            coupons,
          },
        });
      },
      clear: () => {
        set({
          license: {
            cli: "",
            name: "",
            phone: "",
            email: "",
          },
          profile: {
            image: "",
          },
          referralCode: "",
          lastCountry: {
            iso2: "",
            name: "",
          },
          paymentMethods: {
            cards: [],
            wallets: [],
            coupons: [],
          },
        });
      },
    }),
    {
      name: hashKey("account-detail-storage"),
      skipHydration: true,
      storage: createJSONStorage(() => encryptedStorage),
    },
  ),
);

export const useAccountDetailState = (): AccountDetailState => {
  return useAccountDetailStore(state => {
    return state;
  });
};
