/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "@styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import "@styles/layout.css";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@styles/flicking-pagination.css";

import { type GatsbyBrowser } from "gatsby";

import wrapPageElement from "@wrappers/wrap_page_element";

export { wrapPageElement };

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({}) => {
  if (typeof window !== "undefined" && window.scrollTo) {
    window.scrollTo(0, 0);
  }

  return false;
};
