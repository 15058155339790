import CryptoJS from "crypto-js";
import { type StateStorage } from "zustand/middleware";

const SECRET_KEY =
  process.env.GATSBY_ENCRYPTION_STORAGE_KEY || "bnesim-encryption-key";

export const encrypt = (data: any): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decrypt = (encryptedData: string): any => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch {
    return null;
  }
};

export const hashKey = (key: string): string => {
  return CryptoJS.SHA256(SECRET_KEY + key).toString();
};

export const encryptedStorage: StateStorage = {
  getItem: (name: string): any => {
    const encryptedData = localStorage.getItem(name);

    if (!encryptedData) return null;

    try {
      return decrypt(encryptedData);
    } catch (error) {
      console.error("Failed to decrypt state:", error);
      return null;
    }
  },
  setItem: (name: string, value: any): void => {
    localStorage.setItem(name, encrypt(value));
  },
  removeItem: (name: string): void => {
    localStorage.removeItem(name);
  },
};
