exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-plans-[location]-checkout-tsx": () => import("./../../../src/pages/plans/[location]/checkout.tsx" /* webpackChunkName: "component---src-pages-plans-[location]-checkout-tsx" */),
  "component---src-pages-plans-[location]-thank-you-tsx": () => import("./../../../src/pages/plans/[location]/thank-you.tsx" /* webpackChunkName: "component---src-pages-plans-[location]-thank-you-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-templates-plans-tsx": () => import("./../../../src/templates/plans.tsx" /* webpackChunkName: "component---src-templates-plans-tsx" */)
}

