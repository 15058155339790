import CookieType from "@constants/CookieType";
import { type default as CurrencyType } from "@interfaces/Currency";
import { encryptedStorage, hashKey } from "@utilities/encrypted-storage";
import Cookies from "js-cookie";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface ConfigurationState {
  currency: string;
  currencies: CurrencyType[];
  affiliate: string;
  couponCode: string;
  updateCurrency: (value: string) => void;
  resetCurrency: () => void;
  setCurrencies: (value: CurrencyType[]) => void;
  updateAffiliate: (value: string) => void;
  updateCouponCode: (value: string) => void;
  resetCouponCode: () => void;
  reset: () => void;
}

export const useConfigurationStore = create<ConfigurationState>()(
  persist(
    set => ({
      currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
      currencies: [],
      affiliate: "site",
      couponCode: "",
      updateCurrency: (value: string) => {
        if (!value) {
          return;
        }
        set({
          currency: value,
        });
        Cookies.set(CookieType.CURRENCY, value, {
          domain: process.env.GATSBY_ROOT_DOMAIN,
        });
      },
      resetCurrency: () => {
        set({
          currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
        });
      },
      setCurrencies: (value: CurrencyType[]) => {
        set({
          currencies: value,
        });
      },
      updateAffiliate: (value: string) => {
        if (!value) {
          return;
        }
        set({
          affiliate: value,
        });
        Cookies.set(CookieType.AFFILIATE, value, {
          domain: process.env.GATSBY_ROOT_DOMAIN,
          expires: 15,
        });
      },
      updateCouponCode: (value: string) => {
        if (!value) {
          return;
        }
        set({
          couponCode: value,
        });
      },
      resetCouponCode: () => {
        set({
          couponCode: "",
        });
      },
      reset: () => {
        set({
          currency: process.env.GATSBY_DEFAULT_CURRENCY ?? "EUR",
          affiliate: "site",
          couponCode: "",
        });
      },
    }),
    {
      name: hashKey("configuration-storage"),
      skipHydration: true,
      storage: createJSONStorage(() => encryptedStorage),
    },
  ),
);

export const useConfigurationState = (): ConfigurationState => {
  return useConfigurationStore(state => {
    return state;
  });
};
